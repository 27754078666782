<template>
  <external-app-layout></external-app-layout>
</template>

<script>
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
export default {
  name: "Home",
  mixins: [baseComponentMixin],
  components: {
    ExternalAppLayout: () => import("@/views/shared/layout/ExternalAppLayout")
  }
};
</script>
